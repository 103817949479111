import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../containers/SaasModern/Documentation';
import options_exchange_server from '../../../../../../common/src/assets/image/options_exchange_server.png';
import options_google_calendar from '../../../../../../common/src/assets/image/options_google_calendar.png';
import options_outlook_local from '../../../../../../common/src/assets/image/options_outlook_local.png';
import options_vueminder from '../../../../../../common/src/assets/image/options_vueminder.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        Some calendar data sources provide additional options for controlling
        how events and tasks are colored in the output generated by
        PrintableCal.
      </p>
      <h3>Exchange Server</h3>
      <p>
        By default, if PrintableCal is configured to connect to calendars on
        Exchange Server (version 2010 or later), any events that have been
        associated with categories will use those category colors in the
        generated output. If categories haven't been defined in the{' '}
        <Link to="/Documentation/Options/Category-Colors">
          Category Options
        </Link>
        , PrintableCal will automatically import the categories. Unchecking the{' '}
        <strong>Automatically update category colors box</strong> will cause the default behavior
        to be overridden, so events imported from Exchange Server will be colored
        based on any category colors that have been previously defined in the PrintableCal options. If associated categories haven't been defined, events will be colored based on the associated calendar data source instead.
      </p>
      <p>
        <Image alt="" src={options_exchange_server} style={{ width: 891 }} />
      </p>
      <h3>Google Calendar</h3>
      <p>
        Google Calendar events can have their own colors, which override the
        associated calendar color. PrintableCal will use those custom event
        colors when creating calendars that include events from Google Calendar.
        To override this behavior, so the associated calendar data source colors
        are used instead, uncheck the <strong>Use custom event colors</strong>{' '}
        box.
      </p>
      <p>
        <Image alt="" src={options_google_calendar} style={{ width: 891 }} />
      </p>
      <h3>Outlook (Local)</h3>
      <p>
        When PrintableCal is configured to connect to calendars in a locally
        installed version of Outlook, any events that have been associated with
        categories will use those category colors in the generated output. If
        categories haven't been defined in the{' '}
        <Link to="/Documentation/Options/Category-Colors">
          Category Options
        </Link>
        , PrintableCal will automatically import the categories. Unchecking
        the&nbsp;<strong>Automatically update category colors box</strong> will cause the default behavior
        to be overridden, so events imported from Outlook will be colored
        based on any category colors that have been previously defined in the PrintableCal options. If associated categories haven't been defined, events will be colored based on the associated calendar data source instead.
      </p>
      <p>
        <Image alt="" src={options_outlook_local} style={{ width: 891 }} />
      </p>
      <h3>VueMinder</h3>
      <p>
        <a
          href="https://www.vueminder.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          VueMinder{' '}
        </a>
        supports 4 different coloring modes for events, tasks, and notes. They
        can be colored based on calendar data source, category, location, or
        priority. PrintableCal will use those same colors when the{' '}
        <strong>Color items using</strong> option is set to{' '}
        <strong>Same colors shown in VueMinder</strong>. You can choose to
        override this behavior to color items using{' '}
        <strong>Calendar data source colors</strong> or{' '}
        <strong>Category colors</strong>. When using category colors, any
        categories missing from the{' '}
        <Link to="/Documentation/Options/Category-Colors">
          Category Options
        </Link>{' '}
        will be automatically imported when generating calendars
      </p>
      <p>
        VueMinder can also filter events, tasks, and notes based on a variety or
        criteria. By default, PrintableCal will show all items (or filter the
        items based on{' '}
        <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources">
          filtering criteria specified in PrintableCal
        </Link>
        ). To override this behavior, so PrintableCal won't show any items that
        aren't shown in VueMinder, check the box labeled{' '}
        <strong>Exclude items that are filtered in VueMinder</strong>. The
        output you see generated by PrintableCal will then exactly match what
        you see (or don't see) in VueMinder.
      </p>
      <Image alt="" src={options_vueminder} style={{ width: 891 }} />
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Options/Calendar-Data-Source-Options"
      commentsId="commentsplus_post_1205_1652"
      title="Calendar Data Source Options"
      description="PrintableCal can automatically import and color-code categories from Exchange Server, Outlook, and VueMinder. Custom event colors can also be imported from Google Calendar and shown in the generated calendars."
      keywords="printable calendar, color-code events, calendar legend, import categories, category colors"
      content={content}
    />
  );
};

export default Documentation;
